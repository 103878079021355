import React, { useState } from 'react';
import { Route, Routes, Link, useNavigate } from 'react-router-dom';
import Question from './components/Question';
import Summary from './components/Summary';
import answerMappings from './components/AnswerMappings'
import Button from '@mui/material/Button'
import './index.css';

const Home = () => {
  return (
    <div>
      <h1>Love You A Dozen</h1>
      <p>Answer 12 questions about your partner to generate a unique bouquet and message 😊</p>
      <Link to="/question1" className="no-underline">
        <div className="button-box">
          <Button className="button">
            Start
          </Button>
        </div>
      </Link>
    </div>
  );
};

const App = () => {
  const [answers, setAnswers] = useState([]);
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [bouquetImagePath, setBouquetImagePath] = useState(null); // New state
  const navigate = useNavigate();

  // const handleSelect = (questionNumber, selectedOption) => {
  //   setAnswers((prevAnswers) => {
  //     const updatedAnswers = [...prevAnswers];
  //     updatedAnswers[questionNumber - 1] = selectedOption;
  //     return updatedAnswers;
  //   });

  //   setIsAnswerSelected(true);
  //   console.log("YOOOO handle select ran !!");
  // };
  const handleSelect = (questionNumber, selectedOption) => {
      const selectedNumber = answerMappings[selectedOption]; // Use the mapping to get the unique number
      console.log("YOO FAM THIS THE SELECTED NUMBER: ")
      console.log(selectedNumber)
      setAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[questionNumber - 1] = selectedNumber; // Save the mapped number
        return updatedAnswers;
      });

      setIsAnswerSelected(true);
  };


  const handleNext = async (questionNumber) => {
    console.log("handle next called");
    try {
      if (questionNumber === 12 && isAnswerSelected) { // Assuming you have 12 questions now
        // Join the answers to form a query string
        const answersQuery = answers.join(',');
        // 
        // const response = await fetch(`http://127.0.0.1:5000/api/generate_bouquet?answers=${answersQuery}`);
        const response = await fetch(`https://loveyouadozen.com/api/generate_bouquet?answers=${answersQuery}`);
        console.log("[handleNext] THIS IS THE response:")
        console.log(response)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log("[handleNext] THIS IS THE DATA:")
        console.log(data)
        setBouquetImagePath(data.image_path);
        navigate('/summary');
      } else {
        if (isAnswerSelected) {
          setSelectedOption(null);
          navigate(`/question${questionNumber + 1}`);
          setIsAnswerSelected(false);
          console.log("just reset is answer selected for the next question");
        }
      }
    } catch (error) {
      console.error('Error in handleNext:', error);
    }
  };
  
  // const handleNext = async (questionNumber) => {
  //   console.log("handle next called");
  //   try {
  //     if (questionNumber === 3 && isAnswerSelected) {
  //       // https//loveyouadozen.com
  //       // const response = await fetch('http://127.0.0.1:5000/api/generate_bouquet');
  //       const response = await fetch('https://loveyouadozen.com/api/generate_bouquet');
  //       console.log("[handleNext] THIS IS THE response:")
  //       console.log(response)
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }

  //       const data = await response.json();
  //       console.log("[handleNext] THIS IS THE DATA:")
  //       console.log(data)
  //       setBouquetImagePath(data.image_path);
  //       navigate('/summary');
  //     } else {
  //       if (isAnswerSelected) {
  //         setSelectedOption(null);
  //         navigate(`/question${questionNumber + 1}`);
  //         setIsAnswerSelected(false);
  //         console.log("just reset is answer selected for the next question");
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error in handleNext:', error);
  //   }
  // };

  const handleBack = (questionNumber) => {
    if (questionNumber === 1) {
      // Do nothing, as you can't go back from the first question
    } else {
      if (isAnswerSelected) {
        navigate(`/question${questionNumber - 1}`);
      } else {
        navigate(`/question${questionNumber - 1}`);
        setIsAnswerSelected(true); // Set to true only if an answer was selected before
      }
    }
  };


  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        key="question1"
        path="/question1"
        element={<Question
          question="1) What feeling is strongest when you are around this person?"
          options={['Peace', 'Calmness', 'Giddy', 'Happiness']}
          onSelect={(selectedOption) => handleSelect(1, selectedOption)}
          onNext={() => handleNext(1)}
          onBack={() => handleBack(1)}
          setSelectedOption={setSelectedOption}
        />}
      />
      <Route
        key="question2"
        path="/question2"
        element={<Question
          question="2) What did you think when you first met this person?"
          options={['Wow they are so classy and beautiful', 'This person is so sophisticated and beautiful', 'I am beyond charmed by this person', 'How can a person be so well-rounded and successful?']}
          onSelect={(selectedOption) => handleSelect(2, selectedOption)}
          onNext={() => handleNext(2)}
          onBack={() => handleBack(2)}
          setSelectedOption={setSelectedOption}
        />}
      />
      <Route
        key="question3"
        path="/question3"
        element={<Question
          question="3) What do you think about the love that the two of you possess?"
          options={['An everlasting love', 'A passionate love', 'An old-fashioned love', 'An honest and eternal love']}
          onSelect={(selectedOption) => handleSelect(3, selectedOption)}
          onNext={() => handleNext(3)}
          onBack={() => handleBack(3)}
          setSelectedOption={setSelectedOption}
        />}
      />
      <Route
        key="question4"
        path="/question4"
        element={<Question
          question="4) What qualities does this person most resonate with?"
          options={['Kind and honest', 'Innocent and pure-hearted', 'Classy on the outside, warm-hearted on the inside', 'Strong, luxurious, and resilient']}
          onSelect={(selectedOption) => handleSelect(4, selectedOption)}
          onNext={() => handleNext(4)}
          onBack={() => handleBack(4)}
          setSelectedOption={setSelectedOption}
        />}
      />
      <Route
        key="question5"
        path="/question5"
        element={<Question
          question="5) What is the overall vibe of the person after being comfortable with you?"
          options={['Sweet and caring', 'Wise and trustworthy', 'Radiant and charming', 'Hopeful and resourceful']}
          onSelect={(selectedOption) => handleSelect(5, selectedOption)}
          onNext={() => handleNext(5)}
          onBack={() => handleBack(5)}
          setSelectedOption={setSelectedOption}
        />}
      />
      <Route
        key="question6"
        path="/question6"
        element={<Question
          question="6) What does the future look like for you in this relationship?"
          options={['Lifetime of happiness', 'Hopeful and affectionate', 'Everlasting trust and faithfulness', 'Constant strong bond']}
          onSelect={(selectedOption) => handleSelect(6, selectedOption)}
          onNext={() => handleNext(6)}
          onBack={() => handleBack(6)}
          setSelectedOption={setSelectedOption}
        />}
      />
      <Route
        key="question7"
        path="/question7"
        element={<Question
          question="7) What is a key factor for you to be in this relationship?"
          options={["We are long distance, but I gush everytime I think about them and it's worth fighting for", 'They are a person I want to protect and see succeed', 'They are just my dream person - honest and determined in their life', 'They always push me to be the best version of myself']}
          onSelect={(selectedOption) => handleSelect(7, selectedOption)}
          onNext={() => handleNext(7)}
          onBack={() => handleBack(7)}
          setSelectedOption={setSelectedOption}
        />}
      />
      <Route
        key="question8"
        path="/question8"
        element={<Question
          question="8) What does the other person do for you that you appreciate?"
          options={['Heals me, comforting me through hard times', 'Makes me feel like a little kid again through being silly and goofy', 'They are optimistic and encourage me to reach my dreams', 'They are your good luck charm and have a radiant energy that motivates you']}
          onSelect={(selectedOption) => handleSelect(8, selectedOption)}
          onNext={() => handleNext(8)}
          onBack={() => handleBack(8)}
          setSelectedOption={setSelectedOption}
        />}
      />
      <Route
        key="question9"
        path="/question9"
        element={<Question
          question="9) What does this person's energy provide for you?"
          options={['They are kind and resilient, fostering a harmonious relationship', 'They are a genuinely caring energy, providing an escape from a busy life', "They give me a sense of excitement, I'm refreshed whem I'm with them", 'They are my light and empowerse me to be strong']}
          onSelect={(selectedOption) => handleSelect(9, selectedOption)}
          onNext={() => handleNext(9)}
          onBack={() => handleBack(9)}
          setSelectedOption={setSelectedOption}
        />}
      />
      <Route
        key="question10"
        path="/question10"
        element={<Question
          question="10) What feeling is mutual between both of your personalities?"
          options={['Respect', 'Joyfulness', 'Ambition', 'Strength']}
          onSelect={(selectedOption) => handleSelect(10, selectedOption)}
          onNext={() => handleNext(10)}
          onBack={() => handleBack(10)}
          setSelectedOption={setSelectedOption}
        />}
      />
      <Route
        key="question11"
        path="/question11"
        element={<Question
          question="11) What kind of beauty describes this person?"
          options={['Underrated beauty', 'Timeless beauty']}
          onSelect={(selectedOption) => handleSelect(11, selectedOption)}
          onNext={() => handleNext(11)}
          onBack={() => handleBack(11)}
          setSelectedOption={setSelectedOption}
        />}
      />
      <Route
        key="question12"
        path="/question12"
        element={<Question
          question="12) What are your genuine feelings towards this person?"
          options={["They are my best friend, I always feel so happy when I'm with them", 'I want to protect and love this person with all that I have', "I'm so purely happy and joyful when I'm around this person"]}
          onSelect={(selectedOption) => handleSelect(12, selectedOption)}
          onNext={() => handleNext(12)}
          onBack={() => handleBack(12)}
          setSelectedOption={setSelectedOption}
        />}
      />
      <Route path="/summary" element={<Summary answers={answers} bouquetImagePath={bouquetImagePath} />} />
    </Routes>
  );
};

export default App;
