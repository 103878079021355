const answerMappings = {
    "Peace": 9,
    "Calmness": 10,
    "Giddy": 12,
    "Happiness": 8,
    "Wow they are so classy and beautiful": 13,
    "This person is so sophisticated and beautiful": 6,
    "I am beyond charmed by this person": 3,
    "How can a person be so well-rounded and successful?": 19,
    "An everlasting love": 11,
    "A passionate love": 1,
    "An old-fashioned love": 16,
    "An honest and eternal love": 20,
    "Kind and honest": 14,
    "Innocent and pure-hearted": 4,
    "Classy on the outside, warm-hearted on the inside": 5,
    "Strong, luxurious, and resilient": 7,
    "Sweet and caring": 17,
    "Wise and trustworthy": 18,
    "Radiant and charming": 2,
    "Hopeful and resourceful": 31,
    "Lifetime of happiness": 15,
    "Hopeful and affectionate": 21,
    "Everlasting trust and faithfulness": 23,
    "Constant strong bond": 45,
    "We are long distance, but I gush everytime I think about them and it's worth fighting for": 22,
    "They are a person I want to protect and see succeed": 24,
    "They are just my dream person - honest and determined in their life": 28,
    "They always push me to be the best version of myself": 32,
    "Heals me, comforting me through hard times": 25,
    "Makes me feel like a little kid again through being silly and goofy": 46,
    "They are optimistic and encourage me to reach my dreams": 30,
    "They are your good luck charm and have a radiant energy that motivates you": 40,
    "They are kind and resilient, fostering a harmonious relationship": 26,
    "They are a genuinely caring energy, providing an escape from a busy life": 29,
    "They give me a sense of excitement, I'm refreshed whem I'm with them": 34,
    "They are my light and empowerse me to be strong": 37,
    "Respect": 33,
    "Joyfulness": 27,
    "Ambition": 36,
    "Strength": 38,
    "Underrated beauty": 43,
    "Timeless beauty": 44,
    "They are my best friend, I always feel so happy when I'm with them": 35,
    "I want to protect and love this person with all that I have": 39,
    "I'm so purely happy and joyful when I'm around this person": 41,
};

export default answerMappings;
