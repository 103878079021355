import Button from '@mui/material/Button';
import '../index.css';

const Summary = ({ answers, bouquetImagePath }) => {
  console.log('Answers:', answers);

  const downloadImage = async () => {
    try {
      const image = await fetch(bouquetImagePath);
      const imageBlob = await image.blob();
      const imageURL = URL.createObjectURL(imageBlob);

      const link = document.createElement('a');
      link.href = imageURL;
      link.download = 'BouquetImage.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };
  

  // Adjusted style for the image
  const imageStyle = {
    maxWidth: '100%', // ensures the image is not wider than its container
    maxHeight: '80vh', // adjusts the image's max height to be 80% of the viewport height
    objectFit: 'contain', // ensures the aspect ratio is maintained
  };

  return (
    <div>
      <h2>Summary</h2>
      {bouquetImagePath && (
        <div>
          <img src={bouquetImagePath} alt="Final Bouquet" style={imageStyle} />
          <div className="flex-container">
              <div className="download-prompt">Download (mobile):</div>
              <div>Tap and hold image!</div>
              <div className="download-prompt">Download (desktop): </div>
          </div>
          <Button onClick={downloadImage} className="button">
            Download Bouquet Image
          </Button>
        </div>
      )}
    </div>
  );
};

export default Summary;

